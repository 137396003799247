<template>
  <div class="container-form-login">
    <div class="content-login text-center">
      <div class="center-horizontall">
        <div
          @click="this.$router.push({ name: 'Login' })"
          class="content-image cursor-pointer"
        >
          <img
            src="@/assets/logos/logo-bg-white.svg"
            alt="proplat-image.png"
          >
        </div>
      </div>
      <p class="text-title mon-bold mt-5">{{ texts.passwordrecovery.textTitle }}</p>
      <div class="display-flex justify-content-center">
        <p class="text-description mon-regular">{{ texts.passwordrecovery.textDescription }}</p>
      </div>
      <div class="mt-9">
        <v-text-field
          v-model="email"
          type="email"
          :label="texts.passwordrecovery.email.label"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular"
          :placeholder="texts.passwordrecovery.email.placeholder"
          persistent-placeholder
          height="50px"
        >
        </v-text-field>
      </div>
      <div class="center-horizontall mt-5">
        <v-btn
          @click="passwordRecovery"
          :loading="bLoading"
          elevation="0"
          class="button-login mon-bold"
        >
          {{ texts.passwordrecovery.buttonSend }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: {},
    },
  },
  name: "PasswordRecoveryFormLayout",
  data() {
    return {
      //VARIABLES
      email: "",
      bLoading: false,
    };
  },
  methods: {
    passwordRecovery: function () {
      this.bLoading = true;

      const payload = {
        sEmail: this.email,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/users/password`, payload)
        .then((response) => {
          const self = this;
          this.bLoading = false;

          this.mixSuccess(response.data.message);
          setTimeout(function () {
            self.$router.push("/login").catch(() => {});
          }, 3500);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    home: function () {
      this.$router
        .push({
          name: "Home",
        })
        .catch(() => {});
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.text-description {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  width: 70%;
}

.display-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.button-login {
  width: 100%;
  height: 50px !important;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  font-size: 13px;
  text-transform: initial;
}

.text-title {
  font-size: 30px;
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
}

.container-form-login {
  margin-top: 80px;
}

.center-horizontall {
  display: flex;
  justify-content: center;
}

.content-image {
  width: 260px;
  height: 45px;
}

.content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

/* .content-login {
  width: 70%;
  margin-top: -13%;
} */

@media (max-width: 720px) {
  .content-login {
    width: 100%;
  }

  .text-title {
    font-size: 25px;
  }

  .text-description {
    font-size: 14px;
  }
}
</style>